export const arrays = [];

export const mainCategory = {
  sucuriBauturi: "Bauturi",
  frumuseteIngrijirePersonala: "Produse igienizare",
  lactate: "Lactate",
  carneMezeluri: "Carne si mezeluri",
  casaCuratenie: "Casa si curatenie",
  bacanieBrutarie: "Bacanie si brutarie",
  pasteFainoase: "Paste fainoase",
  ceaiCafea: "Ceai si cafea"
};

export const offerTypes = {
  newArrival: {
    link: "new-arrivals",
    title: "New Arrivals"
  },
  weeklyOffer: {
    link: "ofertele-saptamanii",
    title: "Ofertele Saptamanii"
  },
  topDeals: {
    link: "top-deals",
    title: "Top Deals"
  }
};
