import React from "react";

interface IProps {
  headingTitle: string;
  headingStyle: string;
}
const SectionHeading = ({ headingTitle, headingStyle }: IProps) => {
  return (
    <>
      <h4 className={headingStyle}>{headingTitle}</h4>
    </>
  );
};

export default SectionHeading;
