import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import NotFoundPage from "./screens/PageNotFound/NotFoundPage";
import LoadingPage from "./screens/LoadingPage/LoadingPage";

const LandingPage = lazy(() => import("./screens/LandingPage/LandingPage"));
const Navigation = lazy(() => import("./core/navigation/Navigation"));
const FooterSection = lazy(() => import("./components/Footer/FooterSection"));
const SignUpPage = lazy(() => import("./screens/AuthPages/SignUpPage"));
const LoginPage = lazy(() => import("./screens/AuthPages/LoginPage"));
const ForgotPasswordPage = lazy(() => import("./screens/AuthPages/ForgotPasswordPage"));
const NewPasswordPage = lazy(() => import("./screens/AuthPages/NewPasswordPage"));
const MyAccountPage = lazy(() => import("./screens/MyAccountPage/MyAccountPage"));
const PersonalDetails = lazy(() => import("./screens/MyAccountPage/PersonalDetails"));
const MyReturns = lazy(() => import("./screens/MyAccountPage/MyReturns"));
const MyCards = lazy(() => import("./screens/MyAccountPage/MyCards"));
const MyVouchers = lazy(() => import("./screens/MyAccountPage/MyVouchers"));
const ProductsPage = lazy(() => import("./screens/ProductsPage/ProductsPage"));
const CartPage = lazy(() => import("./screens/CartPage/CartPage"));
const SingleProductPage = lazy(() => import("./screens/SingleProductPage/SingleProductPage"));
const PlainContent = lazy(() => import("./screens/TermsPage/PlainContent"));
const AboutUs = lazy(() => import("./screens/AboutPage/AboutUs"));
const NewsletterPage = lazy(() => import("./screens/NewsletterPage/NewsletterPage"));
const OrderDetails = lazy(() => import("./screens/OrderDetails/OrderDetails"));
const RecurrentOrderDetails = lazy(() => import("./screens/OrderDetails/RecurrentOrderDetails"));
const ReturnedOrderDetails = lazy(() => import("./screens/OrderDetails/ReturnedOrderDetails"));
const MyOrders = lazy(() => import("./screens/MyAccountPage/MyOrders"));
const Favorite = lazy(() => import("./screens/FavoritePage/Favorite"));

import styles from "./App.module.scss";
import strings, { ANPC, TermsCondition, DataProtection } from "./core/constants/strings";
import routes from "./core/navigation/routes";

const App = () => {
  return (
    <div>
      <div className={styles.app}>
        <Suspense fallback={<LoadingPage />}>
          <Navigation />
          <Routes>
            <Route path={routes.homepage.route} element={<LandingPage />} />
            <Route path={routes.signUp.route} element={<SignUpPage />} />
            <Route path={routes.logIn.route} element={<LoginPage />} />
            <Route path={routes.forgotPassword.route} element={<ForgotPasswordPage />} />
            <Route path={routes.newPassword.route} element={<NewPasswordPage />} />
            <Route path={routes.cartPage.route} element={<CartPage />} />
            <Route path={routes.singleProduct.route} element={<SingleProductPage />} />
            <Route path={routes.favorite.route} element={<Favorite />} />
            <Route
              path={routes.myAccount.route}
              element={
                <MyAccountPage
                  headingTitle={strings.headings.personalData}
                  activeLinkText={routes.personalDetails.name}
                >
                  <PersonalDetails />
                </MyAccountPage>
              }
            />
            <Route
              path={routes.myOrders.route}
              element={
                <MyAccountPage
                  headingTitle={routes.myOrders.name}
                  activeLinkText={routes.myOrders.name}
                >
                  <MyOrders />
                </MyAccountPage>
              }
            />
            <Route
              path={routes.myReturns.route}
              element={
                <MyAccountPage
                  headingTitle={routes.myReturns.name}
                  activeLinkText={routes.myReturns.name}
                >
                  <MyReturns />
                </MyAccountPage>
              }
            />
            <Route
              path={routes.myCards.route}
              element={
                <MyAccountPage
                  headingTitle={routes.myCards.name}
                  activeLinkText={routes.myCards.name}
                >
                  <MyCards />
                </MyAccountPage>
              }
            />
            <Route
              path={routes.myVouchers.route}
              element={
                <MyAccountPage
                  headingTitle={routes.myVouchers.name}
                  activeLinkText={routes.myVouchers.name}
                >
                  <MyVouchers />
                </MyAccountPage>
              }
            />
            <Route path="/produse">
              <Route index element={<ProductsPage />} />
              <Route path=":category" element={<ProductsPage />} />
            </Route>
            <Route path={routes.anpc.route} element={<PlainContent content={ANPC} />} />

            <Route
              path={routes.termsAndCond.route}
              element={<PlainContent content={TermsCondition} />}
            />
            <Route
              path={routes.privacy.route}
              element={<PlainContent content={DataProtection} />}
            />
            <Route path={routes.simpleOrders.route} element={<OrderDetails />} />
            <Route path={routes.recurrentOrders.route} element={<RecurrentOrderDetails />} />
            <Route path={routes.returnedOrders.route} element={<ReturnedOrderDetails />} />
            <Route path={routes.newsletter.route} element={<NewsletterPage />} />
            <Route path={routes.aboutUs.route} element={<AboutUs />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <FooterSection />
        </Suspense>
      </div>
    </div>
  );
};

export default App;
