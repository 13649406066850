import React from "react";

import SectionHeading from "../../components/headings/SectionHeading";

import strings from "../../core/constants/strings";
import styles from "./styles.module.scss";
import headingStyles from "../../components/headings/styles.module.scss";

const NotFoundPage = () => {
  return (
    <div className={styles.pageNotFoundDiv}>
      <SectionHeading
        headingTitle={strings.pageNotFoundText}
        headingStyle={headingStyles.darkHeadingTitle}
      />
    </div>
  );
};

export default NotFoundPage;
