import React from "react";
import strings from "../../core/constants/strings";
import styles from "./styles.module.scss";
import headingStyles from "../../components/headings/styles.module.scss";
import SectionHeading from "../../components/headings/SectionHeading";

const LoadingPage = () => {
  return (
    <div className={styles.loadingDiv}>
      <SectionHeading
        headingTitle={strings.loadingHeading}
        headingStyle={headingStyles.darkHeadingTitle}
      />
    </div>
  );
};

export default LoadingPage;
