const strings = {
  categories: "Categorii",
  headerInfoText: "Magazinul tau preferat la un click distanta! ",
  search: "Caută produse...",
  showAllResults: "Afișează toate rezultatele",
  showAllProducts: "Afiseaza mai multe produse",
  showFavesBtn: "Vezi produse favorite",
  about: "Despre Ermosa",
  favoriteProducts: "Produse favorite",
  promotions: "Promoții",
  profileModal: {
    vouchers: "Vouchere si carduri cadou",
    logIn: "Conecteaza-te",
    logOut: "Deconectaza-te",
    register: "Inregistreaza-te",
    myAccount: "Contul meu",
    myOrders: "Comenzile mele",
    myReturns: "Retururile mele",
    myCards: "Cardurile mele"
  },
  passwordResetModal: {
    title: "Parola resetata!",
    text: "Parola dumneavoastra a fost resetata. Va rugam sa accesati contul dumneavoastra cu noile detalii introduse. ",
    btn: "Mergi spre autentificare"
  },
  modalCartBtn: "Vezi detalii coș",
  subTotal: "Subtotal",
  currency: "RON",
  productCategoriesHeading: "Categorii produse",
  createNewAccountText: "Creeaza cont",
  activateCouponText: "Activeaza cupon",
  shareText: "Share",
  offers: {
    offersHeading: "Oferta saptamanii",
    timeLeft: "time left:",
    descriptionText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis sed molestie mollis pretium eget aliquet "
  },
  allProductsBtn: "Vezi toate produsele",
  newArrivalsText: "Afiseaza mai multe produse",
  subscribeBtn: "Aboneaza-te",
  form: {
    name: "Nume prenume",
    email: "E-mail",
    phone: "Telefon",
    newsletterCheckBoxText:
      "Doresc sa primesc informatii in legatura cu ofertele, produsele si serviciile oferite de magazinul Ermosa",
    pass: "Parola",
    repeatPass: "Repeta parola",
    forgotPassLink: "Ai uitat parola?",
    connectBtn: "Conecteaza-te",
    signUpWithText: "Inregistreaza-te cu ",
    logInWithText: "Autentifica-te cu",
    newClientText: "Client nou?",
    existingClientText: "Ai deja cont?",
    registerBtn: "Inregistreaza-te",
    fName: "Prenume",
    lName: "Nume",
    termsAndCondCheckboxText: "Sunt de acord cu ",
    termsAndCondLink: "Termenii si Conditiile",
    termsAndCondCheckboxText2: " ermosa.ro",
    forgotPass: "Ai uitat parola?",
    forgotPassBtn: "Trimite",
    dateOfBirth: "Data nasterii",
    changePassBtn: "Schimba parola",
    backToLogin: "Inapoi la autentificare",
    newPassword: "Introduceti parola noua",
    newPass: "Parola noua",
    confirmNewPass: "Reintroduceti parola noua",
    newPassBtn: "Accesati"
  },
  newsletterImgText: "Fi primul care afla ofertele noastre!",
  thankYouModal: {
    heading: "Felicitari!",
    text: "V-ati abonat la Newsletter Ermosa!   Multumim ca faci parte din familia noastra. O sa fiti la curent cu cele mai noi oferte, cele mai mari reduceri la cele mai bune produse de pe piata. Va asteptam cu drag sa ne treceti pragul!",
    button: "Inchide"
  },
  headings: {
    register: "Inregistrare",
    login: "Autentificare",
    personalData: "Date personale",
    myAccount: "Contul meu",
    filters: "Filtre",
    newsletterHeadingSubscribe: "Aboneaza-te la newsletter!",
    vouchersCards: "Vouchere si carduri cadou",
    myCards: "Cardurile mele",
    myOrders: "Comenzile mele",
    myReturns: "Retururile mele"
  },
  breadcrumbDivider: ">",
  pageNotFoundText: "Page not found",
  forgotPassText: "Scrie adresa ta de e-mail și îți vom trimite un link pentru resetare.",
  myAccountMenu: {
    personalDetails: "Date personale",
    myOrders: "Comenzile mele",
    myReturns: "Retururile mele",
    myCards: "Cardurile mele",
    myVouchers: "Vouchere si carduri cadou"
  },
  editBtn: "Editeaza",
  saveBtn: "Salveaza",
  editProfilePicBtn: "Editeaza poza profil",
  addProfilePicBtn: "Adauga poza profil",
  changePassBtn: "Schimba parola",
  ordersCard: {
    orderNrText: "Nr comanda:",
    awbText: "AWB:",
    inProgress: "In procesare",
    cancelled: "Anulata",
    finalised: "Finalizata",
    returned: "Returnata",
    viewDetailsBtn: "Vezi detalii",
    recurrentOrdersBtn: "Vezi detalii/Editeaza"
  },
  orderSummary: {
    myCart: "Cosul meu",
    title: "Sumar comanda",
    myProducts: "Produsele mele",
    productsCost: "Cost produse:",
    deliveryCost: "Cost livrare:",
    discount: "Reducere:",
    totalCost: "Total",
    discountCode: "Cod reducere:",
    homeDelivery: "Livrare la domiciliu",
    storePickUp: "Ridicare din magazin"
  },
  recurringOrderModal: {
    simpleOrder: "Comanda simpla",
    recurringOrder: "Comanda recurenta",
    orderName: "Nume comanda",
    repeat: "Repeta:",
    hour: "Ora",
    date: "Data",
    repeatOrder: "Repeta o data la:",
    infoTitle: "Info",
    orderDescription:
      "O achizitie va fi plasata vineri incepand cu data de 10-10-2022, ora 08:40 si se va repeta in fiecare saptamana.",
    orderReminder: " Nu va faceti griji, va com reaminti cu o ora inainte de trimiterea comenzii."
  },
  days: {
    monday: "Lu",
    tuesday: "Ma",
    wednesday: "Mi",
    thursday: "Jo",
    friday: "Vi"
  },
  weeks: {
    oneWeek: "1 saptamana",
    twoWeeks: "2 saptamani",
    threeWeeks: "3 saptamani",
    oneMonth: "1 luna"
  },
  nextStepBtn: "Urmatorul pas",
  activateBtn: "Activeaza",
  cancelBtn: "Anuleaza",
  categoriesBtn: "Categorii",
  simpleOrders: "Comenzi simple",
  currentOrders: "Comenzi recurente",
  footerMenu: {
    infoText: "Info",
    termsAndCond: "Termeni si conditii",
    privacy: "Protectia datelor personale",
    anpc: "ANPC",
    forYouText: "Pentru tine",
    newsletterSignup: "Inscriere la newsletter",
    coupons: "Cupoane",
    myAccount: "Contul meu",
    contactText: "Contact",
    about: "Despre Ermosa",
    careers: "Cariere",
    socialMediaText: "Social Media"
  },
  copyrightText: "© 2022 ERMOSA | All Rights Reserved",
  addBtn: "Adauga",
  cards: {
    visa: "Debit",
    name: "Nume prenume",
    expiryDate: "Exp:",
    cardLabel: "N/A",
    cardNoText: "Numar card",
    cardExpiryText: "Data expirare",
    cardExpiryPlaceholder: "MM/YY",
    ccvText: "CCV",
    ccvPlaceholder: "421",
    cardholdersName: "Numele de pe card",
    cardNoPlaceholder: "0000 0000 0000 0000",
    cardNamePlaceholder: "Vasile Pop"
  },
  addCard: {
    heading: "Adauga Card"
  },
  subscribeText:
    "Fii la curent cu cele mai noi oferte si reduceri de la cel mai indragit magazin din orasul tau!",
  allProducts: "Toate produsele",
  similarProducts: "Produse similare",
  mightLikeText: "S-ar putea sa-ti placa si",
  productSpecs: {
    heading: "Specificatii",
    generalInfo: "Informatii generale",
    specsText: "Specialitate",
    productType: "Tip produs",
    nutritionalInfo: "Informatii nutritionale",
    quantity: "Cantitate",
    calories: "Calorii / 100 g",
    packagingInfo: "Impachetare",
    packagingQuantity: "Cantitate neta (kg, l)",
    productWeight: "Greutate (Kg)",
    packagingType: "Pet",
    dimensions: "Dimensiuni"
  },
  productPackaging: "Dimensiuni si impachetare",
  generalInfo: "Informatii generale",
  addToCartBtn: "Adauga in cos",
  filters: {
    allProducts: "Toate produsele",
    price: "Pret",
    sorting: "Sortare"
  },
  filterBtn: "Filtreaza",
  orderDetails: {
    orderStatus: "Stare comanda",
    cancelOrderBtn: "Anuleaza comanda",
    returnBtn: "Retur",
    transformRecurrentOrderBtn: "Transforma in comanda recurenta",
    orderedProductsText: "Produse comandate",
    singleOrderDetails: "Detalii comanda",
    recurringOrderDetails: "Detalii comanda recurenta",
    recurringOrderText: "Comanda se repeta ",
    returnedOrderText: "Comanda returnata",
    returnedOrderDetails: "Detalii comanda returnata"
  },
  product: {
    vatText: "Pretul include TVA",
    packagingText: "Pret pe bax",
    productCode: "Cod produs",
    availability: "Disponibilitate",
    productDescription: "Descriere generala"
  },
  aboutPage: {
    AboutErmosa: {
      title: "Despre Ermosa",
      headLine: "Magazinul tau preferat la un click distanta!",
      firstText:
        "Am inceput in anul 2007 cu un magazin deschis in Baia Mare, importand produse din Ungaria, clientii au fost foarte multumiti de calitatea si pretul produselor noastre, asa ca am continuat sa aducem produsele din Ungaria. ",
      secondText:
        "Al doilea magazin l-am deschis in 2010 pe Bulevardul Independentei 17 cu aceleasi produse importate din Ungaria. Acum puteti comanda si online produsele noastre."
    },
    dateContact: {
      companyName: "ERMOSA",
      companyAddress: "Bd. Bucuresti nr. 27",
      companyCity: "430051 Baia Mare",
      companyCountry: "Romania",
      actionCall: "Suna-ne: 0747 981 077",
      actionEmail: "Trimite-ne un mesaj la: office@ermosa.ro"
    },
    socialMedia: {
      title: "Social Media",
      socials: {
        instagram: { name: "instagram", link: "https://www.instagram.com/ermosa.ro/" },
        facebook: { name: "facebook", link: "https://www.facebook.com/ermosa.ro/" },
        youtube: { name: "youtube", link: " https://www.youtube.com/ermosa.ro" }
      }
    },

    testimonials: {
      title: "Testimoniale",
      reviews: {
        review1: {
          author: "Maria Ster, Casnica",
          text: "Foarte multumita de produsele unguresti, am mai cumparat de la Ermosa si o sa mai cumpar, Multumesc.”Maria Ster, Casnica"
        },
        review2: {
          author: "Maria Ster, Casnica",
          text: "Foarte multumita de produsele unguresti, am mai cumparat de la Ermosa si o sa mai cumpar, Multumesc.”Maria Ster, Casnica"
        },
        review3: {
          author: "Maria Ster, Casnica",
          text: "Foarte multumita de produsele unguresti, am mai cumparat de la Ermosa si o sa mai cumpar, Multumesc.”Maria Ster, Casnica"
        }
      }
    },

    postReviewButton: "Scrie parerea ta despre noi"
  },
  review: {
    heading: "Scrie parerea ta despre noi",
    placeholder: "Scrie aici...",
    publishBtn: "Publica"
  },
  orderDetailsHeading: "Detalii comanda",
  recurringOrderDetailsHeading: "Detalii comanda recurenta",
  returnedOrderDetailsHeading: "Detalii comanda returnata",
  loadingHeading: "Loading...",
  deleteAllFiltersText: "Elimina toate filtrele",
  deleteFilters: "Elimina filtre",
  viewProducts: "Vezi produse"
};
export default strings;

export const TermsCondition = {
  title: "Termeni si conditii",
  text: ` <b>1. Conditii Generale</b>
    <br>
    Prezentele Termene si Conditii (denumite in continuare „Documentul”) impreuna cu orice alte documente mentionate in acesta stabilesc termenii si conditiile care reglementeaza utilizarea Site-ului www.ermosa.ro si achizitionarea de produse prin intermediul acestuia.
    <br>
    Termenii mentionati cu majuscule in prezentul Document au intelesul celor prezentate in Anexa 1- Definitii.
    
    <br>Documentul este disponibil pe Site la adresa <b>http://www.ermosa.ro/ </b> si stabileste termenii si conditiile de utilizare ai Siteului/Continutului/Serviciului de catre Vizitator, Membru sau Client. Folosirea, inclusiv dar nelimitandu-se la accesarea, vizitarea, vizualizarea, Site-ului/Continutului/Serviciului si plasarea de Comenzi prin intermediul Site-ului implica acordul direct, deplin si neconditionat al Membrului, Clientului sau Vizitatorului de respectare a Documentului (prezentele termene si conditii) cu toate consecintele si efectele care decurg din acesta. Va rugam sa cititi in intregime Documentul iar in cazul in care nu sunteti de acord cu acesta sau cu orice alte documente mentionate in acesta, va rugam sa nu utilizati acest Site.
    
    <br>Accesul la Serviciu se face exclusiv prin accesarea Siteului public disponibil la adresa <b>www.ermosa.ro </b>
    
    Prin folosirea Siteului/Continutului/Serviciului, Membrul, Clientul sau Vizitatorul este singurul responsabil pentru toate activitatile care decurg prin folosirea acestuia. De asemenea, acesta raspunde pentru orice daune materiale, intelectuale sau electronice sau de orice alta natura produse Siteului, Continutului, Serviciului, ermosa.ro sau oricarui tert cu care ermosa.ro are incheiate contracte, in conformitate cu legislatia romana in vigoare.
    
    <br>Site-ul este adresat doar persoanelor care au cel putin 18 ani si apte din punct de vedere legal sa incheie contracte cu caracter obligatoriu, iar Comenzile online se vor expedia doar catre persoanele cu domiciliul in Romania. Prin crearea unui Cont sau efectuarea unei Comenzi, ermosa.ro va considera ca persoana intruneste conditiile susmentionate. Documentul este valabil incheiat exclusiv in limba romana.
    
    ermosa.ro isi rezerva dreptul de a modifica si actualiza in orice moment Documentul precum si Continutul/Serviciul/Site-ul, fara niciun fel de notificare prealabila si fara nicio obligatie. In cazul in care beneficiarul nu este de acord cu modificarile mentionate mai sus, acesta poate renunta la utilizarea Site-ului si toate serviciile aferente, si poate cere in orice moment catre ermosa.ro anularea Contului, in caz contrar considerandu-se ca modificarile au fost pe deplin acceptate.
    
    <br>Pentru Comenzile plasate pe Site se vor aplica Termenii si conditiile valabile la momentul efectuarii Comenzii.
    
    ermosa.ro
    
    <br><b>2. Politica de vanzare online</b>
    
    <br>2.1. Accesul la Serviciu
    
    <br>2.1.1 Accesul la Serviciu este permis oricarui Utilizator sau Client care are sau isi creaza un Cont pe Site. Prin accesarea Serviciului Membrul/Utilizatorul sau Clientul declara ca accepta prevederile Documentului si isi asuma integral responsabilitatea pentru toate si oricare dintre activitatile realizate prin intermediul Contului deschis pe Site si plasarea de Comenzi.
    
    <br>2.1.2 Prin crearea unui Cont sau prin plasarea unei Comenzi pe Site, Utilizatorul si respectiv Clientul declara ca datele furnizate sunt corecte, complete si adevarate.
    
    <br>2.1.3 In situatia in care <b>ermosa.ro</b> descopera neregularitati si/sau incalcari de orice fel a prevederilor Documentului, sau orice actiuni care care conduc la prejudicierea sau perturbarea in orice fel a activitatii ermosa.ro, isi rezerva dreptul la libera sa alegere, de a anula, limita, restrictiona, suspenda sau exclude accesul Utilizatorului sau Clientului la Continut sau Serviciu si/sau la unele modalitati de plata acceptate, de a sterge sau restrictiona Contul acestuia precum si de a anula Comenzile plasate pe Site.
    
    ermosa.ro acorda permisiunea de utilizarea a Site-ului sau a Serviciului cu conditia respectarii urmatorilor termeni de folosinta:
    
    <br>- este interzisa copierea integrala sau partiala sau distribuirea oricarei parti a Site-ului sau a unor sectiuni ale acestuia, indiferent de mediul de stocare pe care se realizeaza copierea, perecum si copierea in scopul distribuirii sau in orice alte scopuri.
    
    <br>- este interzisa orice interventie care ar putea determina disfunctionalitatatea chiar si temporara a Site-ului, Continutului sau Serviciului
    
    <br>- este interzisa orice alterare sau modificare a oricarei parti a Site-ului, Continutului sau Serviciului, incluzand de asemenea orice continut expediat prin orice modalitate de catre ermosa.ro catre Untilizatori sau Clienti
    
    <br>- este interzisa orice actiune de alterare a performantellor serverelor pe care ruleaza Site-ul
    
    <br>- este interzisa orice actiune care ar putea afecta negativ activitatile corecte, resonabile si permise ale celorlalti Vizitatori, Utilizatori sau Clienti sau care sau care intimideaza, hartuieste ori agreseaza verbal sau vizual orice alt Vizitator, Utilizator sau Client al Site-ului, inclusiv accesarea si utilizarea neautorizata a Conturilor altor persoane pe Site.
    
    ermosa.ro nu solicita Clientilor sau Utilizatorilor sai prin niciun mijloc de comunicare informatii referitoare la date confidentiale, conturi/carduri bancare sau parole personale si astfel va asumati intreaga responsabilitate pentru divulgarea catre orice tert, a datelor sale confidentiale. ermosa.ro isi declina orice responsabilitate, in situatia in care un Utilizator/Client ar fi sau este prejudiciat sub orice forma de catre un tert care ar pretinde ca este/reprezinta interesele ermosa.ro. Daca exista orice suspiciune in acest sens va rugam sa ne informati la adresa de email office@ermosa.ro sau prin intermediul fornularului de contact disponibil pe Site. - este interzisa orice activitate care ar putea facilita sau incuraja actiuni care incarca prevederile enuntate in prezentul Document ori dispozitiile legale aplicabile pentru desfasurarea activitatilor specifice.
    
    <br>- este interzisa orice actiune care poate afecta si interfata cu elemente de securitate ale Site-ului, cu elemente care previn sau restrictioneaza utilizarea sau copierea unui continut sau cu elemente care intaresc limitele de utilizare ale Site-ului sau ale Continutului.
    
    <br>- este interzisa accesarea sau divulgarea catre orice tert care nu are autoritatea legala necesara, a continutul expediat prin orice mijloc de catre ermosa.ro catre Utilizator/ Client atunci cand tertul nu este destinatarul legitim al continutului.
    
    <br>- este permisa postarea de comentarii si orice alte comunicari, transmiterea de sugestii, intrebari sau informatii doar in conditiile pastrarii unui limbaj civilizat si in conditiile in care continutul comunicarilor nu este ilegal, obscen, amenintator, defaimator, nu tulbura in nici un fel viata privata a altor persoane, nu incalca drepturile de proprietate intelectuala, nu contine virusi, nu se refera la continut care nu are legatura cu ermosa.ro, nu reprezinta transmiterea de e-mailuri in masa ori orice alta forma de spam. ermosa.ro nu isi asuma responsabilitatea si nici nu va putea fi obligata la nici un fel de despagubiri pentru orice fel de daune produse de astfel de comunicari.
    
    Vizitatorul, Utilizator sau Clientul garanteaza originalitatea oricarui continut transmis catre ermosa.ro si acorda acestuia si afiliatilor/asociatilor sai dreptul neexclusiv, nelimitat, gratuit, irevocabil si retransmisibil de a folosi, reproduce, modifica, adapta, publica, traduce, crea opere derivate, precum si dreptul de a distribui, de a prezenta aceste continuturi, oriunde si prin orice mijloace. Utilizatorul garanteaza ca are toate drepturile asupra continutului pe care il afiseaza sau il transmite pe Site, prin orice modalitate, astfel incat, prin folosirea acestui continut, sa nu cauzeze prejudicii niciunei terte parti.
    
    Orice incalcare a prezentului Document va avea ca rezultat suspendarea accesului la Serviciile ermosa.ro iar acele actiuni care contravin prevederilor legale vor fi semnalate autoritatilor responsabile.
    
    <br>2.2. Comanda
    
    <br>2.2.1. Efectuarea de Comenzi de Produse pe Site se realizeaza prin adaugarea Produselor dorite in cosul de cumparaturi, urmata de completarea adresei de mail, a numarului de telefon, a informatiilor pentru plata (facturarea comenzii), a informatiilor pentru livrare (expedierea comenzii), alegerea metodei de livrare, iar apoi urmand a finaliza (plasa) Comanda prin alegerea metodei de plata dorite si indicate expres si apasarea butonului Trimite Comanda. Adaugarea de Produse in cosul de cumparaturi, fara a finaliza (plasa) Comanda, nu determina rezervarea automata a acestora.
    
    <br>2.2.2 Dupa finalizarea (plasarea) de catre Client a Comenzii pe Site, ermosa.ro va facilita informarea Clientului asupra stadiului in care se afla Comanda sa, deruland in ordine cronologica urmatoarele etape:
    
    <br>a) Imediat dupa plasarea Comenzii, ermosa.ro Trimite Clientului o prima notificare electronica de receptionare a Comenzii. Aceasta prima notificare nu reprezinta acceptarea Comenzii ci doar confirmarea ca ermosa.ro a receptionat Comanda efectuata. Toate Comenzile trebuie sa fie acceptate ce catre ermosa.ro iar aceasta se efectueaza prin trimiterea unei notificari electronice ulterioare de acceptare a Comenzii conform punctului b) de mai jos. Contractul se considera incheiat intre ermosa.ro si Client in momentul trimiterii de catre ermosa.ro catre Client, prin intermediul postei electronice si/sau SMS, a notificarii electronice de confirmare a Comezii.
    
    <br>b) <b>ermosa.ro</b> verifica disponibilitatea Produselor sau posibilitatea de aprovizionare si in cazul in care acestea sunt disponibile pe stoc sau pot fi aprovizionate, va trimite catre Client o notificare electronica de confirmare a Comenzii.
    
    <br>Onorarea Comenzilor plasate pe Site depinde de disponibilitatea Produselor aferente acestora. In acest sens:
    
    <br>-In cazul in care o parte din Produsele din cadrul Comenzii nu sunt disponibile pe stoc sau nu pot fi aprovizionate, ermosa.ro poate diminua Comanda cu echivalentul acestor Produse, prin contactarea si informarea prealabila in acest sens a Clientului si obtinerea acordului acestuia. In situatia in care in urma contactarii Clientul este de acord cu diminuarea Comenzii ermosa.ro va trimite notificare electronica de confirmare a Comenzii (diminuate conform acordului Clientului). In situatia in care Clientul nu este de acord cu diminuarea Comenzii, ermosa.ro va anula comanda si va trimite Clientului o notificare electronica in acest sens, fara nicio obligatie din partea ermosa.ro si fara ca vreo parte sa poata sa pretinda celeilalte daune-interse.
    
    <br>-In cazul in care niciun produs din cadrul Comenzii nu este disponibil pe stoc sau nu poate fi aprovizionat, ermosa.ro poate anula Comanda, prin trimiterea unei notificari electronice, fara nicio obligatie din partea ermosa.ro si fara ca vreo parte sa poata sa pretinda celeilalte daune-interse.
    
    <br>Trimiterea notificarii electronice de confirmare a Comenzii poate fi amanata pana la primirea de catre ermosa.ro a confirmarii disponibilitatii produselor de catre furnizor sau dupa caz, primirea in depozitul ermosa.ro a Produselor Comandate de Client.
    
    c) Pentru Comenzile confirmate prin trimiterea pe cale electronica conform punctului b) de mai sus, ermosa.ro va pregati Produsele pentru livrare si in cel mai scurt timp dupa livrarea acestora va trimite Clientului o notificare electronica privind confirmarea de expediere a Comenzii.
    
    <br>2.2.3 Prin finalizarea (plasarea) Comenzii, Clientul garanteaza ca toate datele furnizate sunt reale, complete si corecte.
    
    <br>2.2.4 Prin finalizarea (plasarea) Comenzii pe Site, Clientul este de acord ca ermosa.ro il poate contacta prin orice mijloc disponibil si agreat de ermosa.ro, in orice situatie in care este necesara contactarea Clientului.
    
    <br>2.2.5 ermosa.ro poate anula unilateral o Comanda in orice moment, in urma unei notificari prealabile adresate Clientului, fara nicio obligatie ulterioara a vreunei parti fata de cealalta sau fara ca vreo parte sa poata sa pretinda celeilalte daune-interese, incluzand dar fara a se limita la situatiile urmatoare:
    
    <br>- neacceptarea de catre banca emitenta a cardului Clientului sau a tranzactiei sau esuarea/invalidarea tranzactiei online de catre procesatorul de carduri agreat de ermosa.ro, in cazul efetuarii de platii online;
    
    <br>- datele incomplete sau incorecte furnizate de catre Client pe Site;
    
    <br>- activitatea Clientului pe Site poate si/sau produce daune de orice natura, sau prejudiciaza in orice fel ermosa.ro si/sau partenerii acestuia;
    
    <br>- doua incercari de livrare consecutive, esuate (in cazul livrarii prin Curier);
    
    <br>- neridicarea coletului de la Posta Romana intr-un interval de de 10 zile de la data trimiterii de catre Posta Romana catre Clientul a avizului de sosire a coletului;
    
    <br>- orice alte motive obiective invocate te ermosa.ro, inclusiv dar fara a se limita la, inexistenta pe stoc a Produselor sau imposibilitatea de aprovizionare, erori involuntare in privinta prezentarii Produselor sau a pretului acestora, etc.
    
    <br>2.2.6 Refuzul de a procesa o Comanda - ermosa.ro isi rezerva dreptul de a elimina orice Produs de pe Site in orice moment si/sau de a elimina, adauga sau modifica orice materiale sau continuturi de pe Site. Desi vor fi depuse toate eforturile posibile pentru a procesa întotdeauna toate Comenzile, este posibil sa existe circumstante exceptionale in care este posibil sa fim nevoiti sa refuzam procesarea unei Comenzi chiar si dupa ce v-am trimis notificarea electronica de Confirmarea a Comenzii. Ne rezervam dreptul de a face acest lucru în orice moment, fara justificare expresa.
    
    <br>Nu vom fi raspunzatori fata de dumneavoastra sau fata de orice alta terta parte pe motiv că am inlaturat orice Produs de pe acest Site sau ca am înlaturat, adaugat sau modificat orice materiale sau conţinuturi de pe Site sau ca nu am procesat o Comanda, chiar si după ce v-am trimis notificarea electronica de Confirmare a comenzii.
    
    <br>2.2.7. In cazul in care o parte sau toate Produsele comandate de catre Client in cadrul unei Comenzi nu pot fi livrate de catre ermosa.ro iar Clientul a efectuat in prealabil o plata prin virament sau card bancar, ermosa.ro va informa Clientul in cel mai scurt timp posibil in acest sens, va diminua/anula Comanda si va returna in contul Clientului contravaloarea Produselor care nu pot fi livrate in cazul diminuarii Comenzii si respectiv valoarea integrala a sumelor incasate inclusiv costurile de livrare in cazul in care niciunul din Produsele comandate nu sunt disponibile, in termen de maxim 5 zile calendaristice de la data incasarii de catre ermosa.ro a contravalorii aferente Produselor comandate.
    
    <br>2.2.8. In situatia in care Clientul isi exprima optiunea de a anula sau diminua continutul unei Comenzi inainte de confirmarea de catre ermosa.ro a acesteia iar Comanda a fost achitata in prealabil, Clientul va informa in scris ermosa.ro in acest sens. In situatiile de mai sus, ermosa.ro va returna in contul Clientului suma reprezentand diferenta dintre contravaloarea Comenzii initiale si a cea a noii Comenzi - in cazul diminuarii si respectiv intreaga valoarea a Comenzii, inclusiv costurile de livrare - in cazul anularii, in maxim 5 zile lucratoare de la data la care ermosa.ro a incasat in contul bancar sumele aferente Comenzii diminuate sau anulate.
    
    <br>2.2.9. Detaliile de livrare a Produselor incluzand dar nelimitandu-se la timpul necesar de livrare nu constituie o obligatie contractuala din partea ermosa.ro, fara ca vreo parte sa poata sa pretinda celeilalte daune-interese, in cazul in care oricare parte poate fi sau este prejudiciata in orice mod de pe urma incalcarii acestora.
    
    <br>2.2.10. Comenzile care includ Produse cu date de livrare diferite vor fi considerate Comenzi distincte si vor fi livrate separat cu exceptia cazului in care Clientul accepta livrarea intregii Comenzii intr-un interval echivalent Produsului cu termenul de livrare cel mai indepartat.
    
    <br>2.2.11. Comenzile pe Site se efectueaza doar online si nu pot fi efectuate telefonic. In cazul in care intampinati dificultati in efectuarea unei comenzi online va rugam sa contactati ermosa.ro prin mijloacele de contact disponibile pe Site si un consultant va fi bucuros sa asigure sprijin pas cu pas pana la plasarea comenzii.
    
    <br><b>2.3. Produsele si disponibilitatea Produselor</b>
    
    <br>2.3.1. ermosa.ro poate publica pe Site informatii despre Produse si/sau promotii practicate de catre acesta sau de catre oricare alt tert partener, intr-o anumita perioada de timp si in limita stocului disponibil.
    
    <br>2.3.2. Produsele achizitionate prin intermediul Serviciului sunt destinate exclusiv uzului personal al Clientului si nu vor fi destinate revanzarii.
    
    <br>2.3.3. ermosa.ro poate limita unuia sau mai multor Clienti posibilitatea de achizionare a unor Produse disponibile pe Site la un moment dat, in conditiile art. 2.1.
    
    <br>2.3.4. Toate tarifele aferente Produselor prezentate pe Site sunt exprimate in RON, includ TVA si nu includ cheltuielile de livrare. Preturile de livrare sunt detaliate pe Site la sectiunea „Plata si Livrarea” si sunt vizibile de asemenea in cadrul procesului de plasare a Comenzii precum si in informarea electronica pe care o primiti imediat dupa plasarea Comenzii. In cazul alegerii livrarii prin Curier rapid, pentru localitatile care se afla in afara ariei de acoperire, se aplica aditional pretului de livrare mentionat in cadrul procesului de comanda, tarif suplimentar de livrare aferent kilometrilor aditionali. Daca va fi cazul va vom informa in legatura cu acest aspect si va veti putea exprima optiunea de a accepta livrarea in coditiile aplicarii tarifului suplimentar, de a alege o alta metoda de livrare (ex. posta) sau de a renunta la Comanda plasata.
    
    <br>2.3.5. Facturarea Bunurilor si Serviciilor achizitionate se face exclusiv in RON. Factura este inclusa in coletul alaturi de Produsele livrate. In cazul in care plata se efectueaza cu ajutorul cardului bancar, ermosa.ro va debita contul curent al Clientului cu sumele reprezentand contravaloarea Produselor comandate, ulterior transmiterii catre acesta din urma a confirmarii Comenzii.
    
    <br>2.3.6. Tarifele care sunt afisate pe Site taiate cu o linie pot reprezenta preturi recomandate de catre producatori pentru vanzarea in magazine, centre comerciale sau orice alte spatii comerciale unde nu se practica discounturi. Aceste tarife au caracter pur informativ si nu au valoare juridica. ermosa.ro nu poate fi trasa la raspundere pentru orice posibile diferente de preturi aparute fata de alte magazine de vanzare retail.
    
    <br>2.3.7. In cazul platilor online ermosa.ro nu este si nu poate fi facuta responsabila pentru niciun alt cost suplimentar suportat de Client, incluzand dar nelimitandu-se la comisioane de conversie valutara aplicate de catre banca emitenta a cardului acestuia, in cazul in care moneda de emitere a acestuia difera de RON. Responsabilitatea pentru aceasta actiune o poarta numai Clientul.
    
    <br>2.3.8. Toate informatiile folosite pentru descrierea Produselor disponibile pe Site (imagini statice, dinamice, prezentari multimedia, etc.) nu reprezinta o obligatie contractuala din partea ermosa.ro, acestea fiind utilizate exclusiv cu titlu de prezentare.
    
    <br>2.3.9. ermosa.ro poate sa utilizeze si alte produse in descrierea Bunurilor si/sau Serviciilor pe Site (de ex. Accesorii, alte produse) care pot sa nu fie incluse in costurile produselor respective.
    
    <br>2.4. <b>Livrarea Produselor</b>
    
    <br>2.4.1. Livrarea catre Client a Produselor Comandate este efectuata prin intermediul unei firme de curierat rapid sau prin Posta Romana. Se considera ca livrarea a avut loc sau Comanda a fost livrata, de la momentul in care dumneavoastra sau o terta parte indicata de catre dumneavoastra a intrat in posesia fizica a Produselor aferente Comenzii.
    
    <br>In acest sens, firma de curierat rapid, Posta Romana sau tertii parteneri ai firmelor de curierat rapid sau posta, mandatati pentru indeplinirea serviciilor de transport, pot contacta Clientii ermosa.ro prin orice mijloc de comunicare (e-mail, telefon, SMS, mandat postal) in limitele si in scopul prevazut in prezentul articol, adica pentru indeplinirea serviciilor de transport.
    
    <br>2.4.2. ermosa.ro va depune toate eforturile pentru livrarea catre Client a Produselor aferente Comenzilor in intervalul de timp mentionat pe pagina de prezentare a Produsului, in orice caz, in termen de cel mult 30 de zile de la data Confirmarii comenzii.
    
    <br>2.4.3. Termenul estimativ de livrare pentru Produsele aflate pe stoc este de 3-5 zile lucratoare in cazul livrarii prin Posta Romana si de 1-2 zile lucratoare in cazul livrarii prin curier rapid, iar pentru produsele care nu se afla pe stoc, intervalul de livrare este de maximum 30 de zile de la data Confirmarii comenzii.
    
    <br>2.4.3. In cazul in care produsele nu pot fi livrate in termenul sus mentionat, ermosa.ro va informa Clientul, si va oferi optiunea de livrare a Produselor intr-un termen suplimentar, corespunzator circumstantelor. In cazul in care Clientul nu accepta livrarea in termenul suplimentar mentionat, poate opta pentru anularea comenzi, iar in eventualitatea in care plata a fost efectuata prealabil livrarii, sumele platite in temeiul Contractului vor fi rambursate in totalitate Clientului, in termen de cel mult 7 zile de la data la care Clientul a comunicat decizia sa de terminare a Contractului.
    
    <br>Comenzile care contin Produse cu termene de livrare diferite vor fi considerate comenzi distincte si vor fi livrate separat cu exceptia cazului in care Clientul accepta livrarea intregii Comenzii intr-un interval echivalent Produsului cu termenul de livrare cel mai indepartat. In cazul in care se efectueaza mai multe livrari in cadrul aceleiasi Comenzi, Clientul va suporta costurile de livrare pentru fiecare colet trimis.
    
    <br>3. <b>Politica de returnare a Produselor</b>
    
    <br>3.1.1 Clientii beneficiaza de o perioada de 14 zile de a se retrage dintr-un Contract, fara a fi nevoiti sa justificati decizia de retragere.
    
    <br>3.1.2 Perioada de retragere expira in termen de 14 zile de la data la care Clientul sau o terta parte, alta decat transportatorul si indicata de Client, intra in posesia fizica a Produselor sau, în cazul mai multor Produse din aceeasi Comanda care vor fi livrate separat, in termen de 14 zile de la data la care Clientul sau o terta parte, alta decat transportatorul si indicata de Client, intrati în posesia fizica a ultimului produs.
    
    <br>3.1.3 Pentru a exercita dreptul de retragere, Clientul informeaza ermosa.ro cu privire la decizia sa de retragere din contract, inainte de expirarea perioadei de retragere. In acest scop, Clientul poate alege una din urmatoarele variante:
    
    <br>•De a folosi modelul de Formular de retur pe care il poti descarca de aici (vei gasi formular si in colet alaturi de Produsele comandate)
    
    <br>•De a face orice alta declaratie neechivoca in care Clientul isi exprima decizia de retragere din Contract (ex: trimitand un e-mail la office@ermosa.ro, trimitand instiintarea prin intermediul paginii de contact disponibila pe site la adresa http://www.ermosa.ro/contact# sau printr-o scrisoare trimisa prin intermediul serviciilor postale la adresa de retur: ermosa.ro, Baia Mare, bd. Republicii nr. 12/4, judet Maramures).
    
    <br>Pentru a respecta termenul-limita de retragere este suficienta trimiterea din partea Clientului a comunicarii privind exercitarea dreptului de retragere inainte de expirarea perioadei de retragere.
    
    <br>3.1.4 In cazul retragerii din Contract, Clientul va returna Produsele la adresa de retur: ermosa.ro, Baia Mare, bd. Republicii nr. 12/4, judet Maramures, fara intarzieri nejustificate si, in orice caz, in cel mult 14 zile de la data la care a comunicat ermosa.ro decizia sa de retragere din Contract. Termenul este respectat daca Produsele sunt trimise inapoi inainte de expirarea perioadei de 14 zile.
    
    <br>Produsele vor fi returnate folosind orice companie de curierat sau prin intermediul serviciilor postale.
    
    <br>3.1.5 Costurile directe ale returnarii Produselor cat si orice alte costuri suplimentare de returnare sunt suportate de Client. Nu vor fi acceptate/primite coletele continand retururi de Produse, remise cu plata la destinatie.
    
    <br>Prin exceptie de la cele mentionate in paragraful de mai sus al acestui articol, ermosa.ro va suporta costurile de returnare in urmatoarele situatii:
    
    <br>- 3.1.5.1. Coletele prezinta deteriorari severe; 
    
    <br>- 3.1.5.2. Produsele au fost livrate/facturate gresit; 
    
    <br>- 3.1.5.3. Produsele prezinta defecte de fabricatie;
    
    <br>3.1.6 Clientul este singurul responsabil de orice diminuare a valorii Produselor ca urmare a unei manipulari diferite de cea necesara pentru a stabili natura, caracteristicile şi functionarea acestora. Ne rezervam dreptul de a nu accepta retururi in situatia in care Produsele returnate nu sunt in aceeasi stare in care au fost livrate (in ambalajul original cu toate etichetele intacte si documentele care l-au insotit).
    
    Este exceptata de la dreptul de retragere furnizarea de Produse sigilate care nu pot fi returnate din motive de protectie a sanatatii sau din motive de igiena si care au fost desigilate de Client.
    
    <br>3.1.7 In cazul retragerii din Contract, ermosa.ro va rambursa toate sumele pe care le-a primit drept plata din partea Clientului, mai putin costurile de livrare, fara intarzieri nejustificate si în orice caz nu mai tarziu de 14 zile de la data la care ermosa.ro a fost informat in legatura cu decizia de retragere din Contract a Clientului. Suma va fi rambursata utilizand aceeasi modalitate de plata ca si cele folosite de Client pentru tranzactia initiala, cu exceptia cazului in care Clientul a fost de acord cu o alta modalitate de plata, fara a cadea in sarcina Clientului plata de comisioane in urma rambursarii.
    
    <br>3.1.8 Fara a aduce atingere celor de mai sus, este posibil sa retinem rambursarea pana la primirea bunurilor sau pana cand prezentati dovezi in legatura cu returnarea acestora, oricare dintre cele două situatii are loc mai devreme.
    
    <br>3.1.9 In coletul remis catre dumneavoastra veti primi alaturi de factura si documentul cuprinzand instructiunile privind retragerea precum si modelul de formular de retragere.
    
    <br><b>4. Politica de confidentialitate</b>
    
    <br>Intimitatea dumneavoastra este importanta pentru noi. Apreciem increderea pe care ne-o acordati si ne obligam sa protejam si sa aparam orice tip de informatii personale pe care ni le le incredintati.
    
    ermosa.ro in calitate de operator, administreaza in conditii de siguranta si numai pentru scopurile specificate mai jos datele personale furnizate de catre catre dumneavoastra in calitate de Utilizatori sau Clienti ai Site-ului, cu respectarea prevederilor Legii 677/2001 pentru protectia persoanelor cu privire la prelucrarea datelor cu caracter personal si libera circulatie a acestor date.
    
    ermosa.ro poate sa modifice din cand in cand Politica de confidentialitate; va recomandam sa vizitati periodic acesta sectiune pentru a fi la curent cu actualizarile. Daca nu sunteti de acord cu acesta Politica de Confidentialitate trebuie sa intrerupeti utilizarea serviciilor noastre, inclusiv navigarea pe Site.
    
    <br>Ce tip de date personale foloseste ermosa.ro?
    
    <br>•Colectare datelor cu caracter personal
    
    <br>- Cand va creati un cont pe Site sau efectuati o Comanda vi se cere sa mentionati adresa de email, numele si prenumele, adresa pentru facturare si livrare, numarul de telefon, informatii necesare pentru plata.
    
    <br>- Cand doriti sa va abonati la newsletter vi se cere sa introduceti adresa de mail
    
    <br>- Cand doriti sa ne contactati prin intermediul formularului de contact de pe Site vi se cere sa introduceti numele, adresa de emai si telefonul
    
    •Colectarea datelor tehnice - cand vizitati Site-ul nostru, chiar daca nu va creati un Cont sau nu efectuati o Comanda, in scopul imbunatatirii serviciilor oferite prin intermediul Site-ului sau cu scop statistic, este posibil sa colectam anumite informatii cu caracter tehnic despre vizitatorii Site-ului, colectare care se realizeaza din raportul de trafic al serverului: in momentul accesarii Site-ului, sunt furnizate anumite informatii legate de acces cum ar fi adresa de IP, locul de unde a fost accesat site-ul, durata vizitei, sistemul de operare folosit.
    
    <br>Am putea de asemenea obtine informatii despre dumneavoastra atunci cand utilizati anumite servicii de social media.
    
    <br>De ce colecteaza si prelucreaza ermosa.ro datele dumneavoastra personale?
    
    <br>Prin crearea voluntara a Contului sau comandarii de Produse pe Site precum si in alte situatii in care ne furnizati voluntar date cu caracter personal, va exprimati in mod expres si neechivoc consimtamantul ca miniprice4all sa colecteze si sa prelucreze datele dumneavoastra cu caracter personal, atat manual cat si automat, nelimitat teritorial sau temporal, pentru scopurile mentionate mai jos:
    
    <br>•Validarea, facturarea si expedierea Comenzilor precum si informarea Utilizatorilor/Clientilor privind starea si evolutia Comenzilor
    
    <br>•Solutionarea anularilor si retururilor sau a oricaror altor chestiuni de orice natura referitoare la o Comanda, Contract sau Produse achizitionate
    
    <br>•Crearea Contului si Asigurarea accesului Utilizatorului/Clientului la Serviciu
    
    <br>•Informarea Utilizatorilor/Clientilor privind situatia Contului acestora pe ermosa.ro
    
    <br>•Abonarea la newslettere sau alte liste de corespondenta pe Site
    
    <br>•Trimiterea de Newslettere/alerte comerciale periodice, oferte, promotii, mesaje, publicitare si de marketing, prin folosirea postei electronice (email, SMS) si/sau prin apel telefonic, privind Produsele si activitatea ermosa.ro si a tertilor cu care ermosa.ro are relatii de orice natura (inclusiv comerciale).
    
    <br>•Transmiterea de mesaje non-comerciale sau de tip administrativ (privind schimbari in site, schimbari de proceduri, etc.)
    
    <br>•Contactarea Utilizatorului/Clientului la cererea sa voluntara sau in chestiuni de relatii cu clientii
    
    <br>•Statistici interne necesare pentru imbunatatirea calitatii Produselor oferite si a imaginii ermosa.ro si pentru crearea unor elemente caracteristice noi, promotii, functionalitati si servicii noi
    
    <br><b>Potrivit Legii nr. 677/2001 cu respectarea conditiilor de forma si continut acolo prescrise</b>, puteti ca o data pe an sa va exercitati un drept de acces la date furnizate, pentru a afla daca acestea sunt sau nu sunt prelucrate.
    
    <br>De asemenea puteti oricand exercita, in conditiile prevazute de aceeasi lege, un drept de interventie asupra datelor in sensul posibilitatii de a obtine de la ermosa.ro, la cerere si in mod gratuit:
    
    <br>a) rectificarea, actualizarea, blocarea sau stergerea datelor a caror prelucrare nu este conforma legi mai sus mentionate, in special cu privire la datele incomplete sau inexacte,
    
    <br>b) dupa caz, transformarea in date anonime a datelor a caror prelucrare nu este conforma cu legea mai sus mentionata si
    
    <br>c) notificarea catre tertii carora le-au fost dezvaluite datele a oricarei operatiuni efectuate conform literelor a) si b) de mai sus, daca aceasta modificare nu se dovedeste imposibila sau nu presupune un efort disproportionat fata de interesul legitim care ar putea fi lezat.
    
    Aveti de asemenea posibilitatea exercitarii unui drept de opozitie in orice moment, din motive intemeiate si legitime legate de situatia dumneavoastra particulara ca datele care va vizeaza sa faca obiectul unei prelucrari, cu exceptia cazurilor in care exista dispozitii legale contrare. In caz de opozitie justificata, prelucrarea nu mai poate viza datele in cauza. Aceasta optiune determina imposibilitatea accesarii Serviciului.
    
    <br>De asemenea, orice Membru sau Client are dreptul de a se opune in orice moment, in mod gratuit si fara nici o justificare, ca datele care o vizeaza sa fie prelucrate in scop de marketing direct, in numele operatorului sau al unui tert, sau sa fie dezvaluite unor terti intr-un asemenea scop.
    
    Pentru situatiile in care dumneavoastra in calitate de Utilizator sau Client veti solicita stergerea Contului pe Site, agreati si intelegeti ca stergerea datelor personale ce va apartin va fi urmata de anularea contului dumneavoastra din sistem si in consecinta de imposibilitatea de a accesa Serviciile oferite de ermosa.ro
    
    <br><b>Accesul la date personale</b>
    
    <b>ermosa.ro</b> se obliga ca datele colectate de la Utilizator sau Client sa fie prelucrate numai in conformitate cu scopurile declarate in prezentul document si ca nu va publica, inchiria, licentia, transfera etc, date sau baze de date continand informatii referitoare la datele cu caracter personal ale Utilizatorului sau Clientului vreunor terti neimplicati in indeplinirea scopurilor declarate.
    
    <br>Exceptie de la cele prevazute in paragraful de mai sus sunt situatiile in care transferul /accesarea/vizualizarea etc. este solicitata de organele abilitate in situatiile prevazute de reglementarile in vigoare la data producerii evenimentului.
    
    <br><b> Securitate</b>
    
    <br>In conformitate cu legislatia in vigoare privind protectia datelor folosim proceduri rezonabile pentru a preveni accesul neautorizat si folosirea incorecta a datelor personale, utilizam mecanisme fizice, electronice si procedurale de protectie inclusiv proceduri de securitate si restrictii tehnice si fizice de accesare si folosire a datelor personale insa nu putem garanta integritatea absoluta a datelor cu caracter personal si nu putem elimina complet eventuale riscuri.
    
    <b>ermosa.ro </b> nu isi asuma insa responsabilitatea pentru pierderile de informatii sau date cauzate de orice defectiuni sau erori ale serverelor pe care este gazduit sau ale softului cu care este conceput Site-ul.
    
    <br>In cazul in care optati in calitate de Client pentru plata online prin card bancar a Produselor comandate de pe Site, autorizarea si remiterea spre decontare a tranzactiilor de acceptare la plata a cardurilor se realizeaza exclusiv prin intermediul procesatorilor de plati.
    
    <br>Procesatorul/procesatorii de carduri bancare agreat(i) de catre ermosa.ro are(au) dreptul de a accesa/vizualiza orice tip de date/documente, generate in urma unei comenzi emise, comenzi anulate, contract, contract anulat sau al unui contract onorat, pentru a investiga oricare Tranzactie, in cazul in care aceasta exista.
    
    ermosa.ro nu solicita si nu stocheaza informatii referitoare la cardul sau cardurile bancare ale Clientilor, acestea fiind procesate direct pe serverele furnizorului de servicii de plati online.
    
    <br><b/>Link-uri externe</b>
    
    <br>Politica de confidentialitate ermosa.ro se aplica doar datelor furnizate voluntar de catre Utilizator sau Client exclusiv pe Site. Astfel ermosa.ro nu raspunde pentru politica de confidentialitate practicata de catre oricare alt tert la care se poate ajunge prin legaturi, indiferent de natura acestora, in afara Site-ului. Informatiile de orice fel, incluzand informatiile cu caracter personal furnizate unor astfel de pagini nu intra sub controlul sau autoritatea ermosa.ro si acesta nu poate fi facut responsabil pentru orice utilizare a acestora in orice mod.
    
    <br><b>5. Calitate si garantii </b>
    
    <br>Produsele comercializate de ermosa.ro beneficiaza de garantia de conformitate potrivit prevederilor legii 449/2003.
    
    <br> Detaliile aferente fiecarui Produs sunt furnizate in pagina de prezentare a acestuia din cadrul Site-ului.
    
    <br>In acest sens, in cazul incheierii unui Contract conform Documentului, ne obligam sa livram catre dumneavoastra Produse care sunt in concordanta cu Contractul, fiind raspunzatori fata de dumneavoastra pentru orice lipsa de conformitate care exista in momentul livrarii. Se intelege ca Produsele sunt in conformitate cu Contractul daca:
    
    <br>i.Sunt conforme cu descrierea oferita de catre noi si au aceleasi calitati cu cele pe care le-am prezentat pe Site
    
    <br>ii.Sunt adecvate pentru scopurile pentru care produsele de tipul respectiv sunt utilizate in mod normal si
    
    <br>iii.Prezinta calitatea si parametrii de performanta normali produselor de acelasi tip si care pot fi asteptate in mod rezonabil.
    
    <br>Raspunderea noastra este angajata in cazul in care lipsa de conformitate se produce in termen de doi ani de la data livrarii Produselor. Pana la prezentarea de probe contrare, lipsa de conformitate survenita in termen de 6 luni de la data livrarii Produselor este considerata a fi existat in momentul livrarii acestuia, exceptand cazul in care prezumtia este incompatibila cu natura produsului sau cu lipsa de conformitate.
    
    <br>In conformitate cu art. 10 al Legii 449/2003, in cazul lipsei conformitatii Clientul in calitate de consumator are dreptul de a solicita vanzatorului sa i se aduca produsul la conformitate, far plata, prin reparare sau inlocuire sau sa beneficieze de reducerea corespunzatoare a pretului ori de rezolutionarea contractului privind acel produs, in conditiile prevazute de legea mentionata.
    
    <br>In limita maxima prevazuta de lege, dar far a exclude nimic din ceea ce nu poate fi exclus in mod legal in cazul consumatorilor, negam orice alte garantii de orice alta natura.
    
    <br>Niciun aspect din prezenta clauza nu va afecta drepturile dumneavoastra in calitate de consumator sau dreptul de a va retrage din Contract.
    
    <br><b>6. Utilizarea Continutului</b>
    
    <br>Puteti vizualiza, descarca, utiliza, copia sau dispune in orice alt mod de Continutul Site-ului (inclusiv continutul accesat prin intermediul Site-ului) doar in urmatoarele conditii: (1) Continutul poate fi utilizat numai pentru scopuri personale sau necomerciale si nu poate fi redistribuit fara permisiunea expresa scrisa a ermosa.ro (2) Continutul nu va fi modificat sau alterat in niciun fel (3) orice drept de autor sau de proprietate intelectuala sa fie pastrate.
    
    <br>Este interzisa orice utilizare de Continut in afara scopurilor mentionate expres in prezentul Document.
    
    <br>Cu exceptia celor expres prevazute in acest Document sau a unor acorduri de utilizare distincte, nu veti putea utiliza, descarca, incarca, copia, printa, afisa, efectua, reproduce, publica, licentia, posta, transmite, lega la, expune, include, distribui sau redistribui Continutul Site-ului, in totalitate sau partial, precum si a participa la transferul, vanzarea, distributia de materiale realizate prin reproducerea, modificarea sau afisarea Continutului, in totalitate sau partial, fara aprobarea prealabila scrisa a ermosa.ro.
    
    Continutul, asa cum este definit in Anexa la prezentul Document, care include dar fara a se limita la: logo-uri, simboluri, imagini, text si sau continut multimedia precum si orice alte elemente ale acetuia sunt proprietatea exclusiva a ermosa.ro caruia ii sunt rezervate toate drepturile in acest sens in mod direct sau indirect (prin licente de utilizare si/sau publicare sau prin alte mijloace).
    
    <br>Niciun Continut transmis catre Client sau Utilizator, prin orice mijloc de comunicare sau dobandit de catre acesta prin accesare, vizitare, vizualizare sau orice alt mod nu constitituie o obligatie contractuala din partea ermosa.ro si/sau angajatului ermosa.ro care a mijlocit transferul de continut (in cazul in care acesta exista), fata de respectivul Continut.
    
    <br><b>7. Comunicari/Informari</b>
    
    <br>7.1 Pentru buna dumneavoastra informare, datele de identificare si de contact ale ermosa.ro sunt prezentate pe Site in forma actualizata.
    
    <br>7.2 Prin utilizarea Serviciului, a formularului de contact sau a oricarei alte modalitati de contactare disponibila pe Site, permiteti si consimtiti ca ermosa.ro sa va contacteze prin orice mijloc disponibil, inclusiv prin mijloace de comunicare electronice (ex: emai, SMS).
    
    <br>7.3 Contactarea noastra prin utilizarea formularului de contact sau prin alte mijloace disponibile pe Site nu reprezinta nici un fel de angajament din partea ermosa.ro de a va contacta, cu exceptia cazurilor prevazute de lege. De asemenea ne rezervam dreptul de a nu raspunde comunicarilor de orice natura care nu au legatura cu produsele sau serviciilor de pe Site sau cu un Contract incheiat intre noi.
    
    <br>7.4 Este de mentionat faptul ca legislatia in vigoare prevede ca unele informatii sau comunicari transmise sa fie efectuate in scris, Astfel, cand utilizati Site-ul acceptati ca orice comunicare cu noi se va efectua in principal in scris (pe cale electronica prin contactare pe email sau postarea informatiilor pe Site sau pe cale postala).
    
    <br>In scopuri contractuale, sunteti de acord cu modalitatea de comunicare in scris (electronica sau postala) si admiteti ca toate contractele, informarile, comunicarile, datele si orice alte corespondente sa sa fie facute in scris. Aceasta prevedere nu afecteaza insa drepturile dumneavoastra legale.
    
    Comunicarea va fi considerata primita si inmanata in mod corespunzator de indata ce este postata pe Site, la 24 de ore de la trimiterea unui e-mail sau la trei zile de la data trimiterii prin posta a oricarei scrisori. Pentru a dovedi inmanarea oricarei comunicari, va fi suficient sa se dovedeasca, in cazul unei scrisori, ca aceasta a fost expediata in mod corespunzator, stampilata si introdusa in cutia postala si, in cazul unui e-mail, ca acesta a fost trimis la adresa de e-mail specificata a destinatarului.
    
    <br><b>8. Limitarea raspunderii</b>
    
    <br>Daca nu este prevazut altfel in mod expres in prezentul Document, raspunderea noastra in legatura cu orice Produs achizitionat prin intermediul Site-ului este limitata strict la pretul de achizitie a Produsului respectiv. Dea asemenea ermosa.ro nu poate fi tinuta responsabila in niciun fel fata de Utilizatori, Clienti sau Vizitatori decat in limita prevederilor din prezentul Document.
    
    <br>Daca nu se prevede altfel in prezentul Document si in limita maxima permisa de lege, ermosa.ro nu va fi tinuta sa raspunda, in nicio situatie, pentru pagube speciale, accidentale, colaterale, indirecte sau punitive, pierderea veniturilor sau profiturilor, pierderea castigurilor, pierderea afacerii, pierderea fondului de comert, pierderea tranzactilor, pierderea de date, pierderea de contracte, pierderea de economii anticipate, pierderea folosintei unei proprietati, costurile executarii obligatiilor prin substituirea persoanelor, a echipamentelor sau servicilor si costuri de timp mort de gestionare sau de lucru, indiferent de cum s-au produs aceste situatii, si fie ca sunt produse din culpa (inclusiv neglijenta), prin incalcarea obligatiilor contractuale sau din orice alte motive, si indiferent daca puteau fi prevazute sau nu in mod rezonabil la data contractarii.
    
    <br>Partile sunt de acord ca toate descrierile de Produse, informatiile si materialele precum si serviciile furnizate sunt oferite „asa cum sunt”, fara nicio garantie de orice fel.
    
    <b>ermosa.ro</b> nu ofera niciun fel de garantii directe sau indirecte ca:
    
    <br>• Utilizarea Serviciului, Continutul sau Produsele obtinute prin intermediul Serviciului va fi potrivit cerintelor sau asteptarilor beneficiarului;
    
    <br>• Accesul si folosirea Serviciul se va desfasura in permanenta in cele mai bune conditii, fara intreruperi, erori de functionare si in deplina siguranta;
    
    <br>Serviciilor nu li se vor aplica suplimentar niciun fel de conditii, garantii sau orice alti termeni (inclusiv orice termeni impliciti cu privire la calitatea, compatibilitatea satisfacatoare in conformitate cu descrierea), cu exceptia celor prevazuti in mod explicit in prezentul Document.
    
    <b>ermosa.ro </b>nu isi asuma raspunderea pentru orice pierdere sau prejudiciu suportat de Urilizatori sau Clienti ca urmare a:
    
    <br>1.Eventualelor modificari pe care ermosa.ro le-ar putea aduce Serviciilor sau ca urmare a interuperilor temporare sau definitive aduse de catre ermosa.ro in furnizarea Serviciilor;
    
    <br>2.Pierderii, stergerii, coruperii sau eventualelor erori de stocare a continutului transmis de parteneri in cadrul procesului de utilizare a Serviciilor;
    
    <br>3.Nerespectarii de catre parteneri a obligatiei de a comunica exclusiv informatii corecte si autentice cu privire la identitate, date de contact si orice alte date transmise;
    
    <br>4.Nerespectarii de catre parteneri a obligatiei de a asigura securitatea contului si a datelor de acces.
    
    <br>Site-ul poate contine link-uri, spoturi sau bannere publicitare cu legaturi catre alte site-uri apartinand unor terte parti, care nu sunt proprietatea si nici nu se afla sub controlul ermosa.ro. ermosa.ro nu isi asuma raspunderea pentru continutul, politica, practicile sau regulile sau regulile oricaror alte site-uri si nici pentru utilizarea acestora si recomanda luarea la cunostinta a termenilor si conditiilor aferente fiecaruia dintre site-urile vizitate.
    
    Avand in vedere potentialul pentru erori in stocarea si transmiterea informatiilor digitale si natura deschisa a acestui Site, nu garantam acuratetea si securitatea informatiilor transmise sau obtinute de pe acest Site decat daca se specifica altfel in mod expres in Document.
    
    Nu acceptam nicio raspundere pentru nicio pierdere sau deteriorare care rezulta din orice atac de tip refuz serviciu, virus sau orice alt software sau material care este periculos sau daunator din punct de vedere tehnologic pentru computerul, echipamentul, datele sau materialele Dvs. si care rezulta din utilizarea acestui site web ori din descarcarea continuturilor acestuia sau a unor continuturi inspre care va redirectioneaza acest site web.
    
    ermosa.ro este exonerata de orice vina in situatia in care, in urma utilizarii Siteului, Serviciului, Continutului si/sau al transmiterii Continutului de catre ermosa.ro prin orice mijloc (electronic, telefonic, etc.) si in orice mod (ex. dar fara a se limita la: afisare pe Site, trimitere e-mail, transmitere de catre un angajat al ermosa.ro), aceasta utilizare poate sau produce pagube de orice natura Membrului, Clientului, Vizitatorului si/sau oricarui tert implicat in acest transfer de Continut.
    
    Materialele de promovare (incluzand dar nelimitandu-se la banner-ele de pe Site, de pe paginile de social media sau reclame comerciale), au caracter exclusiv de prezentare si utilizarea acestor materiale nu poate fi interpretata ca fiind o obligatie asumata de ermosa.ro de a detine efectiv in stoc pentru scopuri de comert, produsele ce apar sau pot sa apara in cadrul acestora. Astfel, doar produsele prezentate pe Site care au indicate pretul de vanzare si caracteristicile sunt disponibile pentru vanzare.
    
    <br><b>10. Disponibilitatea Serviciului</b>
    
    <b>ermosa.ro</b> isi rezerva dreptul de a modifica structura si interfata oricarei pagini sau subpagini a Site-ului, in orice moment, si de a intrerupe temporar sau permanent, partial sau in totalitate Serviciile puse la dispozitie prin intermediul acestui Site, urmand sa onoreze toate obligatiile contractuale aflate in derulare, pana la stingerea acestora.
    
    <br><b>11. Forta majora</b>
    
    <br> Nici una din parti nu va fi raspunzatoare pentru neexecutarea obligatiilor sale contractuale, daca o astfel de neexecutare la termen si/sau in mod corespunzator, total sau partial este datorata unui eveniment de forta majora. Forta majora este evenimentul imprevizibil, in afara controlului partilor si care nu poate fi evitat.
    
    <br>Daca in termen de 15 (cincisprezece) zile de la data producerii lui, respectivul eveniment nu inceteaza fiecare parte va avea dreptul sa notifice celeilalte parti incetarea de plin drept a Contractului fara ca vreuna dintre ele sa poata pretinde celeilalte alte daune-interese.
    
    <br><b>12. Legea aplicabila – Jurisdictia </b>
    
    <br>Prezentul Contract este supus legii romane. Eventualele litigii aparute intre ermosa.ro si Vizitatori, Utilizatori si Clienti se vor rezolva pe cale amiabila sau, in cazul in care aceasta nu va fi posibila, litigiile vor fi solutionate de instantele judecatoresti romane competente de la sediul ermosa.ro.
    
    <br>Prezentul Document a fost redactat si va fi interpretat in conformitate cu legislatia romana.
    
    <br><b>13. Integralitatea Documentului </b>
    
    <br>Prezentul Document reprezinta intregul acord dintre dumneavoastra in calitate de Vizitator, Utilizator sau Client si ermosa.ro în legatura cu obiectul oricarui Contract si substituie orice acord, intelegere sau aranjament anterior dintre Dvs. si ermosa.ro, fie verbal, fie in scris.
    
    <br>Atat dumneavoastra cat si noi admitem ca, prin încheierea unui Contract, nici dumneavoastra si nici noi nu ne-am bazat pe nicio reprezentare, actiune sau promisiune data de cealaltă parte sau care poate fi subînteleasa din orice s-a spus ori s-a scris in negocierile dintre dumneavoastra si noi inainte de Contractul respectiv, cu exceptia cazului în care acest lucru este prevazut în mod expres in acest Document.
    
    <br>Nici Dvs., nici noi nu vom utiliza nicio cale de atac cu privire la orice declaratie neadevarata facuta de cealalta parte, fie verbal, fie în scris, inainte de data oricarui Contract (decat daca respectiva declaratie neadevarata a fost facuta in mod fraudulos), iar orice cale de atac a celeilalte parti va fi cauzata de incalcarea Contractului, conform prevederilor acestui Document.
    
    <br><b>14. Transferul Drepturilor si Obligatiilor </b>
    
    Contractul dintre ermosa.ro si dumneavoastra in calitate de Client are caracter obligatoriu pentru ambele parti, dar şi pentru succesorii si cesionarii acestora.
    
    In calitate de Client, nu aveti dreptul sa transferati, sa cesionati, sa grevati sau sa dispuneti in orice alt fel de Contract sau de oricare din drepturile sau obligatiile dumneavoastra care rezulta din acesta, fara consimtamantul prealabil scris al ermosa.ro. ermosa.ro poate transfera, cesiona, greva, sub-contractata sau dispune in orice alt fel de un Contract sau de oricare din drepturile sau obligatiile din Contract, în orice moment pe durata acestuia, cu mentiunea ca orice astfel de transfer, cesionare, grevare sau orice alta dispunere nu va afecta drepturile legale ale Clientului si va asigura indeplinirea in tocmai de catre terta parte a obligatiilor asumate de ermosa.ro fata de Client.
    
    <br><b>15. Dreptul la stergerea datelor </b>
    
    <br>Dreptul de ștergere NU oferă un drept absolut de “a fi uitat”. Persoanele fizice au dreptul de a șterge datele cu caracter personal și de a împiedica procesarea în circumstanțe specifice:
    
    <br>• În cazul în care datele cu caracter personal NU mai sunt necesare în raport cu scopul pentru care au fost inițial colectate/prelucrate;
    
    <br>• Când individul își retrage consimțământul;
    
    <br>• Atunci când individul se opune prelucrării și NU există un interes legitim pentru continuarea prelucrarii;
    
    <br>• Datele personale au fost prelucrate ilegal (adică încălcând GDPR);
    
    <br>• Datele cu caracter personal trebuie să fie șterse pentru a respecta o obligație legală;
    
    <br>• Datele personale sunt prelucrate în raport cu oferta de servicii a societății informaționale unui copil.
    
    <br>Stergerea datelor se poate face din contul clientului butonul "Eliminati datele personale".\n
    
    <br><b>16. Confidentialitatea</b>
    
    <br> Informatiile de orice natura furnizate catre ermosa.ro prin orice mijloc de comunicare, insemnand dar fara a se limita la orice comentarii, intrebari, feedback, idei, sugestii sau alte comunicari sau informatii despre sau referitoare la Site-ul ermosa.ro, functionalitatea sau imbunatatirea acestuia vor deveni si vor ramane proprietatea ermosa.ro, de la data transmiterii acestora.
    
    <br>Prin transmiterea de informatii sau materiale de orice natura prin intermediul Site-ului, oferiti acces nerestrictionat si irevocabil al ermosa.ro la acestea si dreptul de a utiliza, a reproduce, a afisa, a modifica, a transmite si a distribui aceste materiale sau informatii. Sunteti de asemenea de acord ca ermosa.ro sa poata utiliza in mod liber, in interes propriu, ideile, conceptele, know-how-urile sau tehnicile pe care le-ati trimis prin intermediul Site-ului. ermosa.ro nu va constitui subiect de obligatii referitoare la confidentialitatea informatiilor trimise, cu exceptia celor specificate de legislatia in vigoare.
    
    <br>Clientul nu va dezvalui in niciun mod catre terte parti informatii referitoare la Comanda/Contractul incheiat cu ermosa.ro fara consimtamantul prealabil scris al acestuia.
    
    <br>In urma inscrierii in baza de date a ermosa.ro va exprimati consimtamantul expres, in limitele legislatiei in vigoare, de a fi contactat de catre terti parteneri ai ermosa.ro in scopul realizarii operatiunilor si prestarii de servicii referitoare la procesarea comenzilor (ex: companii de curierat), furnizori de servicii de marketing, alti furnizori de servicii in legatura cu executarea Contractului sau cu care ermosa.ro poate dezvolta programe comune de ofertare pe piata a Produselor, etc.
    
    <br><b>17. Dispozitii finale</b>
    
    <br>Fiecare dintre prevederile prezentului Document este independenta, in consecinta, daca orice astfel de prevedere este sau devine nula sau nu poate fi pusa in executare in orice privinta potrivit legii, aceasta nu va afecta valabilitatea sau punerea in executare a celorlalte prevederi ale Documentului.
    
    <br>În cazul in care, in orice moment pe durata Contractului, nu reusim sa insistam asupra indeplinirii stricte de catre Client a oricareia dintre obligatiile care decurg dintr-un Contract sau a oricarei prevederi ale prezentului Document si/sau daca nu reusim sa ne exercitam oricare dintre drepturile sau caile de atac la care avem dreptul în conformitate cu respectivul Contract sau prezentul Document, acest lucru nu va constitui in niciun caz o renuntare sau o limitare a unor astfel de drepturi sau cai de atac si nu va scuti Clientul de indeplinirea respectivelor obligatii.
    
    <br>Renuntarea de catre noi la invocarea neexecutarii unei obligatii contractuale nu inseamna renuntarea la invocarea neexecutarilor ulterioare ce decurg din Contract sau Document.
    
    <br>Nicio renuntare din partea noastra la oricare dintre prevederile Documentului sau la oricare din drepturile sau caile de atac ce decurg din Contract incheiat intre ermosa.ro si Client nu va avea efect decat daca este declarata in mod expres ca fiind o renuntare si comunicata in scris.
    
    <b>ermosa.ro</b> isi rezerva dreptul de a putea efectua orice modificari ale prezentului Document, precum si orice modificari ale Site-ului/structurii acestuia/Continutului/Serviciului precum si orice continut fara notificare prealabila si fara nici o obligatie ulterioara.
    
    <br>In limita prevederilor Termeni si Conditii, ermosa.ro nu va putea fi facuta responsabila pentru eventualele erori aparute pe site din orice cauza, inclusiv din cauza unor modificari, setari, etc., care nu sunt facute de catre administratorul site-ului. ermosa.ro isi rezerva dreptul de a introduce bannere publicitare de orice natura si/sau legaturi pe oricare pagina a Site-ului, cu respectarea legislatiei in vigoare.`
};

export const ANPC = {
  title: `ANPC `,
  text: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate condimentum condimentum condimentum adipiscing. Vitae risus sollicitudin maecenas neque. Non ullamcorper tellus urna, quis convallis dignissim viverra vestibulum. Sollicitudin tempus, id tempus a maecenas pretium vulputate.
  Accumsan egestas gravida proin in sed gravida sed. Sed varius lectus ultrices ullamcorper quis id imperdiet sed. Fringilla sit augue dictumst at tellus. Ullamcorper cursus nec pellentesque nibh est. Tristique pellentesque morbi pellentesque quis mattis leo fermentum velit, nisi. Sed venenatis lectus euismod enim viverra in a habitasse. Eros, tincidunt fermentum, arcu viverra semper ultricies suspendisse vitae.
  Auctor ultricies tempus eget scelerisque venenatis dui. Duis dictum nunc egestas tellus feugiat massa. Arcu aliquam leo amet, laoreet pharetra mollis diam cras venenatis. Erat dictum est tellus convallis enim condimentum ac neque. Purus sed neque quis eros, amet in. Elementum nunc malesuada id fermentum dictum hac ut quis aliquet. Vel mattis gravida elit feugiat. Ultricies at enim pellentesque pellentesque. Interdum purus phasellus fermentum amet. Sed in cursus adipiscing integer pellentesque odio vitae. Eget quis interdum nunc at urna massa, vivamus arcu. Lorem enim nisi, augue egestas convallis gravida libero nec. Egestas ornare est montes, adipiscing lacinia odio urna. At mauris turpis massa vulputate in egestas pharetra.
  Bibendum nunc, est, nisl consequat, placerat aliquam imperdiet ornare. Libero, tellus cras aenean diam mi pellentesque. Consequat risus nunc ornare pulvinar augue urna, donec magna. Fringilla vitae mattis in felis adipiscing felis. Eu ipsum faucibus hendrerit pharetra. Senectus curabitur vel at non volutpat. Pellentesque viverra scelerisque quis cursus odio. Velit elementum, velit sem tristique sed nibh blandit dolor, in. Tempus nullam at nibh id nibh nec pharetra imperdiet interdum. Amet, dui, orci fringilla eu. Diam ipsum dictum gravida ultricies. Vulputate posuere vulputate at blandit tincidunt nisi convallis leo.
  Sed ultrices dignissim ultricies quisque bibendum malesuada et a. Nunc et sapien neque ac porttitor eleifend ornare. Egestas eget vel, auctor tortor lobortis eget nisl. Viverra orci bibendum amet platea at non justo, sagittis elementum. Euismod est eget quis pretium parturient aliquet. Leo non augue a tortor id ut nam eu. Convallis turpis cursus vestibulum, tortor lobortis et, tellus amet ornare. Id platea urna urna neque. Congue proin adipiscing habitant rutrum tempor orci. Rhoncus, sit ultrices lorem purus, pellentesque vitae. Amet, aliquam natoque elementum lectus. Velit bibendum proin aenean semper. Posuere elementum gravida id aliquam tristique enim. Aliquet vitae massa faucibus elementum. In enim tellus tincidunt erat.
  `
};

export const DataProtection = {
  title: `Protectia datelor `,
  text: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate condimentum condimentum condimentum adipiscing. Vitae risus sollicitudin maecenas neque. Non ullamcorper tellus urna, quis convallis dignissim viverra vestibulum. Sollicitudin tempus, id tempus a maecenas pretium vulputate.
  Accumsan egestas gravida proin in sed gravida sed. Sed varius lectus ultrices ullamcorper quis id imperdiet sed. Fringilla sit augue dictumst at tellus. Ullamcorper cursus nec pellentesque nibh est. Tristique pellentesque morbi pellentesque quis mattis leo fermentum velit, nisi. Sed venenatis lectus euismod enim viverra in a habitasse. Eros, tincidunt fermentum, arcu viverra semper ultricies suspendisse vitae.
  Auctor ultricies tempus eget scelerisque venenatis dui. Duis dictum nunc egestas tellus feugiat massa. Arcu aliquam leo amet, laoreet pharetra mollis diam cras venenatis. Erat dictum est tellus convallis enim condimentum ac neque. Purus sed neque quis eros, amet in. Elementum nunc malesuada id fermentum dictum hac ut quis aliquet. Vel mattis gravida elit feugiat. Ultricies at enim pellentesque pellentesque. Interdum purus phasellus fermentum amet. Sed in cursus adipiscing integer pellentesque odio vitae. Eget quis interdum nunc at urna massa, vivamus arcu. Lorem enim nisi, augue egestas convallis gravida libero nec. Egestas ornare est montes, adipiscing lacinia odio urna. At mauris turpis massa vulputate in egestas pharetra.
  Bibendum nunc, est, nisl consequat, placerat aliquam imperdiet ornare. Libero, tellus cras aenean diam mi pellentesque. Consequat risus nunc ornare pulvinar augue urna, donec magna. Fringilla vitae mattis in felis adipiscing felis. Eu ipsum faucibus hendrerit pharetra. Senectus curabitur vel at non volutpat. Pellentesque viverra scelerisque quis cursus odio. Velit elementum, velit sem tristique sed nibh blandit dolor, in. Tempus nullam at nibh id nibh nec pharetra imperdiet interdum. Amet, dui, orci fringilla eu. Diam ipsum dictum gravida ultricies. Vulputate posuere vulputate at blandit tincidunt nisi convallis leo.
  Sed ultrices dignissim ultricies quisque bibendum malesuada et a. Nunc et sapien neque ac porttitor eleifend ornare. Egestas eget vel, auctor tortor lobortis eget nisl. Viverra orci bibendum amet platea at non justo, sagittis elementum. Euismod est eget quis pretium parturient aliquet. Leo non augue a tortor id ut nam eu. Convallis turpis cursus vestibulum, tortor lobortis et, tellus amet ornare. Id platea urna urna neque. Congue proin adipiscing habitant rutrum tempor orci. Rhoncus, sit ultrices lorem purus, pellentesque vitae. Amet, aliquam natoque elementum lectus. Velit bibendum proin aenean semper. Posuere elementum gravida id aliquam tristique enim. Aliquet vitae massa faucibus elementum. In enim tellus tincidunt erat.
  `
};
