import { mainCategory } from "../constants/arrays";
const routes = {
  homepage: {
    name: "Home",
    route: "/"
  },
  products: {
    name: "Produse",
    // route: "/produse/:category"
    route: "/produse/"
  },
  singleProduct: {
    name: "Single product page",
    route: "/:productName"
  },
  favorite: {
    name: "Produse favorite",
    route: "/favorite"
  },
  signUp: {
    name: "Inregistrare",
    route: "/inregistrare"
  },
  logIn: {
    name: "Autentificare",
    route: "/autentificare"
  },
  forgotPassword: {
    name: "Resetare Parola",
    route: "/resetare-parola"
  },
  newPassword: {
    name: "Introduceti parola noua",
    route: "/parola-noua"
  },
  myAccount: {
    name: "Contul meu",
    route: "/contul-meu"
  },
  termsAndCond: {
    name: "Termeni si conditii",
    route: "/termeni-si-conditii"
  },
  anpc: {
    name: "ANPC",
    route: "/anpc"
  },
  privacy: {
    name: "Protectia datelor personale",
    route: "/protectia-datelor-personale"
  },
  aboutUs: {
    name: "Despre Ermosa",
    route: "/despre-ermosa"
  },
  personalDetails: {
    name: "Date personale",
    route: "/contul-meu/date-personale"
  },
  myOrders: {
    name: "Comenzile mele",
    route: "/comenzile-mele"
  },
  simpleOrders: {
    name: "Comenzile mele simple",
    route: "/comenzile-mele/comenzi-simple"
  },
  recurrentOrders: {
    name: "Comenzile mele recurente",
    route: "/comenzile-mele/comenzi-recurente"
  },
  returnedOrders: {
    name: "Comenzile mele recurente",
    route: "/comenzi-returnate"
  },
  myReturns: {
    name: "Retururile mele",
    route: "/retururile-mele"
  },
  myCards: {
    name: "Cardurile mele",
    route: "/cardurile-mele"
  },
  myVouchers: {
    name: "Vouchere si carduri cadou",
    route: "/voucherele-mele"
  },
  meat: {
    name: "Carne si produse din carne",
    route: "produse/" + mainCategory.carneMezeluri.replaceAll(" ", "-").toLowerCase()
  },
  pasta: {
    name: "Fainoase",
    route: "produse/" + mainCategory.bacanieBrutarie.replaceAll(" ", "-").toLowerCase()
  },
  bread: {
    name: "Panificatie",
    route: "produse/" + mainCategory.bacanieBrutarie.replaceAll(" ", "-").toLowerCase()
  },
  drinks: {
    name: "Bauturi",
    route: "produse/" + mainCategory.sucuriBauturi.replaceAll(" ", "-").toLowerCase()
  },
  cleaning: {
    name: "Produse de igienizare",
    route: "produse/" + mainCategory.frumuseteIngrijirePersonala.replaceAll(" ", "-").toLowerCase()
  },
  allProducts: {
    name: "Toate produsele",
    route: "produse/toate-produsele"
  },
  chicken: {
    name: "Carne pui",
    route: "produse/carne/pui"
  },
  porc: {
    name: "Carne vita",
    route: "produse/carne/vita"
  },
  beef: {
    name: "Carne porc",
    route: "produse/carne/porc"
  },
  turkey: {
    name: "Carne curcan",
    route: "produse/carne/curcan"
  },
  forYouText: {
    name: "Pentru tine",
    route: "/pentru-tine"
  },
  newsletter: {
    name: "Newsletter",
    route: "/newsletter"
  },
  coupons: {
    name: "Cupoane",
    route: "/cupoane"
  },
  careers: {
    name: "Cariere",
    route: "/cariere"
  },
  about: {
    name: "Despre Ermosa",
    route: "/despre-ermosa"
  },
  facebook: {
    name: "",
    route: "https://www.facebook.com/"
  },
  instagram: {
    name: "",
    route: "https://www.instagram.com/"
  },
  youtube: {
    name: "",
    route: "https://www.youtube.com/"
  },
  cartPage: {
    name: "Cosul meu",
    route: "/cosul-meu"
  }
};

export default routes;
